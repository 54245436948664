* {
    box-sizing: border-box;
}
h1, h2, h3, h4 {
  text-align: center;
}

p {
 text-align: center;
 font-weight: 200;
 font-size: smaller;
}

input[type=text],
input[type=email], 
select, 
textarea {                    
  padding: 12px;                     
  border: 1px solid #ccc;
  border-radius: 4px;                     
  box-sizing: border-box;                     
  margin-top: 6px;                     
  resize: vertical;                  
  color: #ccc;   
}    

input[type=submit] {              
  background-color: #4CAF50;              
  color: white;              
  padding: 12px 20px;              
  border: none;              
  border-radius: 4px;              
  cursor: pointer;              
}  

input[type=submit]:hover {              
  background-color: #BADA55;   
  border: none;           
}

input[type=textarea] {
  min-height: 200px;
  height: 200px;
}

.container {              
  border-radius: 5px;              
  margin: 5px;
  padding: 20px;
  max-width: 700px;
  min-width: 300px !important;
} 

.emailConfirmation {
  background: #2bb276;
  font-size: large;
  color: #f2f2f2;
  padding: 10px 0px;
  margin: 10px 0px;
  display: block !important;
}

.emailConfirmation * {
  color: #b22b67;
  display: hidden !important;
}

.emailFailure { 
  background: rgba(175, 76, 80, 0.7);
  font-size: large;
  color: #f2f2f2;
  padding: 10px 0px;
  margin: 10px 0px;
  display: block !important;
}

.hidden {
  display: none !important;
}

Button {
  background: #812bb2 !important;
  color: #2d283e !important;
  border: 1px solid #812bb2 !important;
  border-radius: 5px;
  margin: auto 40% !important;
  width: 20%;
  padding: 5% 5% !important;
  min-width: 100px;
  text-align: initial;
}

Button:hover, Button:active {
  color: #d0d7e1 !important;
  background: #2d283e !important;
  border: 1px solid #812bb2 !important;
}

Form {
  width: 30%;
  text-align: initial;
}

.socialContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 22px 30%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 500px) {
  .socialContainer {
    margin: 22px 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  input, textarea {
    width: 100% !important;
  }

  Button {
    margin: auto 30% !important;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }
}

